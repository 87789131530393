<template>
  <v-dialog
    :value="openedValue"
    max-width="700px"
    @click:outside="close()"
    @keydown.esc="close()"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">
          {{ $t('views.external_catalog.synchro_lines.forms.dialog.import.title') }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-alert
              type="warning"
              prominent
              dense
            >
              {{
                $t(
                  'views.external_catalog.synchro_lines.forms.dialog.import.text',
                  { 'selected': nbSelectedItems }
                )
              }}
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            class="py-1"
          >
            Appliquer le tag:
          </v-col>
          <v-col
            cols="12"
            sm="12"
            class="py-1"
          >
            <v-text-field
              v-model="tagName"
              label="Tag"
              hide-details
              clearable
              dense
              outlined
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          color="red darken-2"
          @click="close()"
        >
          <v-icon left>
            mdi-cancel
          </v-icon>
          {{ $t('views.external_catalog.synchro_lines.forms.dialog.import.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          :disabled="loading"
          raised
          color="red darken-1"
          class="white--text"
          @click="submit()"
        >
          <v-icon left>
            mdi-import
          </v-icon>
          {{ $t('views.external_catalog.synchro_lines.forms.dialog.import.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import snackbarMixin from '@/mixins/snackbar'
import taskMixin from '@/mixins/task'

export default {
  name: 'SynchroLinesImportModal',
  mixins: [snackbarMixin, taskMixin],
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    resolveSelectedItems: {
      type: Function,
      default: () => [],
    },
    nbSelectedItems: {
      type: Number,
      required: true,
    },
    tagPrefix: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      tagName: null,
      lastTaskId: null,
    }
  },
  computed: {
    openedValue: {
      get() {
        return this.opened
      },
      set(value) {
        this.$emit('update:opened', value)
      },
    },
  },
  watch: {
    openedValue (value) {
      if (value) {
        this.resolveDefaultTagName()
      }
    },
  },
  methods: {
    resolveDefaultTagName() {
      const now = new Date()
      this.tagName = [
        this.tagPrefix,
        now.getFullYear() +
          ('' + (now.getMonth() + 1)).padStart(2, '0') +
          (now.getDate()),
        this.randomTagSuffix(),
      ].join(' - ')
    },
    close() {
      this.loading = false
      this.openedValue = false
      this.tagName = null
    },
    async submit() {
      this.loading = true

      let tagId = null

      try {
        const responseTag = await this.$axios.post(
          'catalog/tags',
          { name: this.tagName }
        )
        tagId = responseTag.data.id
      } catch (error) {
        let error_message = error.response.data['hydra:description'] ?? error.response.data['detail'] ?? 'No context'
        this.snackbarError(
          this.$i18n.t('tags.messages.creation.failed', { error: error_message })
        )

        this.loading = false

        return
      }

      try {
        const responseImport = await this.$axios.post(
          'external_catalog/synchro_lines/import',
          {
            ids: await this.resolveSelectedItems(),
            tagId: tagId,
          }
        )

        this.taskCreated(responseImport.data.id)
        this.$emit('success')
        this.close()

      } catch (error) {
        this.taskFailed(error.response.data['hydra:description'] ?? error.response.data['detail'] ?? null)
        this.loading = false
        this.resolveDefaultTagName()
      }
    },
    randomTagSuffix () {
      const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789'

      // very weak way to generate a random suffix to guarantee uniqueness of the tag
      // however it should be enough and if it already exists, it will fail at creation
      return [...Array(3).keys()]
        .map(() => characters.charAt(Math.floor(Math.random() * characters.length)))
        .join('')
    },
  },
}
</script>
